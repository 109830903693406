import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import HackavizFooter from '../../components/HackavizFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={1} title={'Hackaviz'} description={'Deuxième édition'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`HACKAVIZ 2019`}</h1>
        <p>{`Deuxième édition
L’association Toulouse Dataviz `}<em parentName="p">{`(TDV)`}</em>{` organise son “Hackaviz”. Concours de visualisation de données en temps limité, seul ou en équipe, doté de récompenses, ouvert à un très large public.`}</p>
        <h1>{`LE PRINCIPE`}</h1>
        <p>{`Raconter une histoire avec des graphiques à partir d’un jeu de données original seul ou en équipe.
A partir du moment ou les données sont mises à disposition, les candidats ont 10 jours pour rendre leur création.`}</p>
        <ul>
          <li parentName="ul">{`Date de mise à disposition des données : 21 Mars 2019`}</li>
          <li parentName="ul">{`Date limite de remise des contributions : 31 mars 2019`}</li>
          <li parentName="ul">{`Date de l’événement de remise des prix : 4 Avril 2019`}</li>
        </ul>
        <h1>{`PRÉPARER L’HACKAVIZ`}</h1>
        <p>{`Voici des newsletters pour vous préparer à l'Hackaviz.`}</p>
        <Button link={'https://mailchi.mp/439a7efc0da9/newsletter-hackaviz-1'} text={'#1 Pourquoi visualiser ?'} mdxType="Button" />
        <Button link={'https://mailchi.mp/812c06efff99/newsletter-hackaviz-2-comment-visualiser?e=8d7bb8102c'} text={'#2 Comment visualiser ?'} mdxType="Button" />
        <Button link={'https://mailchi.mp/d2cf12e7358e/newsletter-hackaviz-3?e=8d7bb8102c'} text={'#3 Des outils et des exemples'} mdxType="Button" />
        <Button link={'https://us20.campaign-archive.com/?e=&u=6d06abd4e903b49060d162a89&id=ccbbe897d8'} text={'#4 Exemples & entrainements'} mdxType="Button" />
        <h1>{`LE JEU DE DONNÉES`}</h1>
        <p>{`Un jeu de données `}<strong parentName="p">{`original`}</strong>{` regroupe des données ouvertes concernant les communes de la région Occitanie.`}</p>
        <Button link={'/hackaviz/2019-data'} type={'internal'} text={'Télécharger les données'} mdxType="Button" />
        <h1>{`LES RÈGLES`}</h1>
        <p>{`Les candidats peuvent être des individus ou des équipes. Le nombre de personnes regroupées sous une candidature doit être précisé à l’inscription `}<em parentName="p">{`(maximum 4 personnes)`}</em>{`. Une fois inscrit, le candidat – ou l’équipe – s’engage à envoyer sa contribution avant la date de fin du concours.`}</p>
        <p>{`Les candidats s’engagent à autoriser la publication de leur contribution sur le site toulouse-dataviz.fr à l’issue du Hackaviz `}<em parentName="p">{`(après l’annonce des résultats)`}</em></p>
        <p>{`Le jour du début du Hackaviz, les candidats reçoivent un lien vers une page de téléchargement contenant`}</p>
        <ul>
          <li parentName="ul">{`les données au format xlsx, csv ou geojson,`}</li>
          <li parentName="ul">{`un dictionnaire des données expliquant la nature des observations et la définition des variables`}</li>
          <li parentName="ul">{`Les candidats peuvent ensuite utiliser tous les outils qu’ils souhaitent pour explorer ces données et en proposer une représentation visuelle à base de graphiques.`}</li>
          <li parentName="ul">{`Les candidats peuvent effectuer tous types de calcul à partir de ce jeu de données`}</li>
          <li parentName="ul">{`hormis des fonds de carte, les candidats ne sont pas autorisés à collecter d’autres données que celles fournies.`}</li>
          <li parentName="ul">{`Les candidats enverront leur contribution par mail avant la date limite à une adresse qui sera communiquée lors de la récupération des données.`}</li>
          <li parentName="ul">{`Le format de restitution de cette analyse visuelle sera au `}<strong parentName="li">{`format pdf`}</strong>{` et ne `}<strong parentName="li">{`devra pas dépasser l’équivalent de 2 pages A4`}</strong>{`.`}</li>
          <li parentName="ul">{`Dans le cas d’une création interactive ou utilisant des services web, le candidat `}<em parentName="li">{`(ou l’équipe)`}</em>{` enverra un mail contenant uniquement l’URL valide de la réalisation.`}</li>
          <li parentName="ul">{`Les candidats s’attacheront à ajouter des éléments contextuels pour commenter le ou les graphiques.`}</li>
          <li parentName="ul">{`Les candidats fourniront la liste des outils utilisés pour la réalisation des graphiques`}</li>
        </ul>
        <h1>{`ÉVALUATION`}</h1>
        <p>{`Les contributions seront jugées par un jury de spécialistes proposé par les organisateurs. Ces personnalités n’auront pas le droit de concourir pour le Hackaviz, ni de collaborer avec aucune des équipes en compétition.`}</p>
        <p>{`Les contributions seront évaluées en fonction de différents critères dont les suivants `}<em parentName="p">{`(liste non exhaustive, ordre non priorisé)`}</em>{`.`}</p>
        <ul>
          <li parentName="ul">{`Pertinence de la visualisation des données pour servir la narration/histoire abordée.`}</li>
          <li parentName="ul">{`Originalité de la visualisation`}</li>
          <li parentName="ul">{`Maîtrise des règles de la visualisation de données`}</li>
        </ul>
        <p>{`Le jury du Hackaviz est un jury souverain, ses décisions sont donc définitives. Les résultats seront annoncés lors d’un Meetup exceptionnel en présence du jury et des candidats `}<em parentName="p">{`(les lauréats seront appelés à commenter leur réalisation)`}</em>{`.`}</p>
        <HackavizFooter year={'2019'} mdxType="HackavizFooter" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      